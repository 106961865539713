

export const data = [
  {
    id: 1,
    title: "Kraftshala expands its marketing launchpad programmes.",
    image:"https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42p3xsk.jpeg",
    subImage: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42p77js.png",
    readmore: "...Read More",
    link:"https://www.telegraphindia.com/edugraph/news/headline-kraftshala-expands-its-marketing-launchpad-programmes-adds-new-course-in-digital-media/cid/1841718",
  },
  {
    id: 2,
    title: "How consumer-driven marketing can increase reach.",
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42p8v1l.png",
    subImage: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42pa4ko.png",
    readmore: "...Read More",
    link:"https://yourstory.com/2021/08/consumer-driven-marketing-increase-reach ",
  },
  {
    id: 3,
    title: "Kraftshala announces free training sessions for professionals.",
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42pcti5.png",
    subImage: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42pe2s1.png",
    readmore: "...Read More",
    link:"https://www.afaqs.com/companies/kraftshala-announces-free-training-sessions-for-professionals-and-b-school-students ",
  },
  {
    id: 4,
    title:
      "Hindi Learners Can Benefit From These New Age Courses In Native Language",
    image:"https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42pk7n2.png",
    subImage: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42pmpcs.png",
    link:"https://www.ndtv.com/education/hindi-learners-can-benefit-from-these-new-age-courses-in-native-language-5675596/amp/1",
  },
  {
    id: 5,
    title: "Kraftshala launches online digital marketing course",
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42pohs1.png",
    subImage:"https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42pqmgb.png",
    link:"https://www.thehindu.com/education/information-on-admissions-new-courses-scholarships-mous-partnerships-and-more/article35758777.ece",
  },
];
