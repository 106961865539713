import React from 'react';
import Slider from 'react-slick';
import { data } from './data';
import './styles.scss';

const NewsSection = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: true,
    pauseOnHover: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    arrows:true,
    responsive: [
   
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div>
      <div className='doted-bg-div'>
        <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42puu7u.png" className='left-bg' alt='left-bg' loading='lazy'/>
        <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46lggto.png" className='right-bg' alt='right-bg' loading='lazy'/>
      </div>
    
      <div className="mlp-main-placements mlp-main news-section-wrapper">
        <div className="placements-container basl-placement-container" >

          <div className='news-heading-div'>
            <h2>In the News</h2>
            <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46llo26.png" className='news-images' alt="->" loading='lazy'/>
          </div>
       
          <div className='placement-slider mlp-news-slider'>
            <Slider {...settings}>
              {data.map((news) => (
                <div key={news.id} className='news-cards'>
                  <div className='upper-section-image'>
                    <a href={news.link} target="_blank">
                      <img src={news.image} style={{ height:"100%",backgroundSize:"contain"}} alt={news.title} loading='lazy' />
                    </a>
                    
                  </div>
                  <div className='lower-section-text'>
                    <a href={news.link} target="_blank">
                      <img src={news.subImage} loading='lazy' alt='Sub Image'  style={{ backgroundSize: 'contain',height:"100%"}} />
                      <p className='text-desc'>{news.title}<span style={{color:"#F1AE13"}}>{news?.readmore}</span></p>
                    </a>
                    
                  </div>
                </div>
              ))}
            </Slider>
          </div>


        </div>
      </div>
    </div>
  );
};

export default NewsSection;
