export const newsData = [
  {
    image:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6py8dux.webp",
    compImage:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6pz14ey.webp",
    name: "VARUN SATIA",
    position: " Founder & CEO, Kraftshala",
    company: "Ex-  FMS , BITS",
  },
  {
    image:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6py9rl5.webp",
    compImage:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6pz2zd0.webp",
    name: "Eshu Sharma",
    position: "Co-founder, Kraftshala",
    company: "Ex- FMS, PEC",
  },
  {
    image:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6pyd00u.webp",
    compImage:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6pz5vvw.webp",
    name: "Nishtha Jain",
    position: " Marketing, Kraftshala.",
    company: "Ex-IIM Luckow, NIT",
  },
  {
    image:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6pyf4bg.webp",
    compImage:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6pz80ri.webp",
    name: "Piyush Dhanuka",
    position: "Strategic Partnerships",
    company: "Ex - IIM Calcutta",
  },
  {
    image:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6pyk6nl.webp",
    compImage:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6pz2zd0.webp",
    name: "Tejas Chaudhari",
    position: "Performance Marketing Lead - Ecommerce",
    company: "Ex - IIM Lucknow",
    mtop: "half",
  },
  {
    image:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6py2iwg.webp",
    compImage:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6pz9d2u.webp",
    name: "Arjun Sood",
    position: "Client partner",
    company: "Ex - IIM Lucknow",
  },
  {
    image:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6pyw04a.webp",
    compImage:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6pzba07.webp",
    name: "Ayushi Mona",
    position: "Director Marketing",
    company: "Ex - MICA",
  },
  {
    image:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6py0rpp.webp",
    compImage:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6pzd0j7.webp",
    name: "Mani Makkar",
    position: "Product Marketer",
    company: "Ex - MICA",
  },
];
